/* eslint-disable import/no-extraneous-dependencies */
import { FC, StrictMode, useEffect } from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ErrorBoundary } from '@sentry/react';
import { Result } from 'antd';
import Routing from 'routes';
import store, { persistor } from 'store';
import useVersionControl from 'utils/useVersionControl';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';
import 'simplebar-react/dist/simplebar.min.css';
import 'tippy.js/dist/tippy.css';
import 'gantt-task-react/dist/index.css';
import 'swiper/swiper.min.css';
import 'quill/dist/quill.snow.css';
import dayjs from 'dayjs';
import PushNotification from './components/PushNotification';

dayjs.extend(relativeTime);
dayjs.extend(utc);

interface AppProps {}

const App: FC<AppProps> = () => {
  useEffect(() => {
    window.addEventListener(
      'dragover',
      (e) => {
        e.preventDefault();
      },
      false,
    );
    window.addEventListener(
      'drop',
      (e) => {
        e.preventDefault();
      },
      false,
    );

    // @ts-ignore
    window.activeVersionModal = false;
  }, []);

  useVersionControl();

  return (
    <StrictMode>
      {/* @ts-ignore */}
      <ErrorBoundary fallback={<Result status="warning" title="Something went wrong. Please reload the page." />}>
        <Provider store={store}>
          {/* @ts-ignore */}
          <PersistGate loading={null} persistor={persistor}>
            <Routing />
            <PushNotification />
          </PersistGate>
        </Provider>
      </ErrorBoundary>
    </StrictMode>
  );
};

export default App;
