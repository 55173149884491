import { FC } from 'react';
import { Link } from 'react-router-dom';
import { Menu, MenuItemProps } from 'antd';
import CountRequestBadge from 'components/CountRequestBadge';
import CountUnreadInboxBadge from 'components/CountUnreadInboxBadge';
import { checkHasPermission, checkHasRole } from 'utils/auth';
import { UserResponse } from 'types';
import { MenuItemBaseLinkType, MenuItemType } from './config';

interface AppMenuItemProps {
  item: MenuItemType;
  user: UserResponse;
  classNameLabel?: string;
  selectedItem?: MenuItemBaseLinkType;
  isSuperUser?: boolean;
  onClick?: MenuItemProps['onClick'];
}

const MenuItem: FC<AppMenuItemProps> = ({ item, user, classNameLabel, selectedItem, isSuperUser, onClick }) => {
  const allowed =
    (!item.permission || (item.permission && checkHasPermission(user, item.permission))) &&
    (!item.role || (item.role && checkHasRole(user, item.role)));

  if (!allowed || (item.needsSuperUser && !isSuperUser)) {
    return null;
  }

  if ('submenu' in item) {
    const res = item.submenu.find((subItem) => checkHasPermission(user, subItem.permission));

    if (!res) return null;

    const childSelected = item.submenu.find((subItem) => subItem.path === selectedItem?.path);

    return (
      <Menu.SubMenu
        key={item.key}
        icon={item.icon}
        title={<span className={classNameLabel}>{item.label}</span>}
        className={childSelected ? 'app-menu-submenu-selected' : undefined}
      >
        {item.submenu.map((subItem) => {
          if (
            (subItem.needsSuperUser && !isSuperUser) ||
            (subItem.permission && !checkHasPermission(user, subItem.permission)) ||
            (subItem.role && !checkHasRole(user, subItem.role))
          ) {
            return null;
          }

          return (
            <Menu.Item key={subItem.path} className={selectedItem?.path === subItem.path ? 'app-menu-item-selected' : undefined}>
              <Link className={classNameLabel} to={subItem.path}>
                {subItem.label}
              </Link>
            </Menu.Item>
          );
        })}
      </Menu.SubMenu>
    );
  }

  return (
    <Menu.Item
      key={item.path}
      icon={item.icon}
      className={selectedItem?.path === item.path ? 'app-menu-item-selected' : undefined}
      onClick={onClick}
    >
      <Link to={item.path}>
        <span className={classNameLabel}>{item.label}</span>
        {item.label === 'Requests' && <CountRequestBadge />}
        {item.label === 'Inbox' && <CountUnreadInboxBadge />}
      </Link>
    </Menu.Item>
  );
};

export default MenuItem;
