import { FC, useState } from 'react';
import { Button } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { SlPencil } from 'react-icons/sl';
import ProjectSelect from 'modules/project/components/ProjectSelect';
import appRoutes from 'config/appRoutes';

interface ProjectTagProps {
  project: any;
  canEdit?: boolean;
  onChange: (project: any) => void;
}

const ProjectTag: FC<ProjectTagProps> = ({ project, canEdit = true, onChange }) => {
  const [edit, setEdit] = useState(!project);

  return (
    <div style={{ display: 'inline-flex', width: '100%', gap: 6, alignItems: 'center' }}>
      {edit || !project ? (
        <>
          <ProjectSelect
            className="mb-0"
            bordered={false}
            onChange={(value, option: any) => {
              setEdit(false);
              if (option?.resource?.uuid !== project?.uuid) {
                onChange(option?.resource);
              }
            }}
          />

          <div
            className="pointer"
            style={{ fontSize: '20px', paddingRight: 10, lineHeight: 1 }}
            onClick={() => {
              setEdit(false);
            }}
          >
            <CloseCircleOutlined className="red" />
          </div>
        </>
      ) : (
        <>
          <Link
            to={`${appRoutes.PROJECTS}/${project.uuid}`}
            className="secondary"
            style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
          >
            {project.customer?.abbreviation} - {project.customer?.business_name} - {project.title}
          </Link>

          {canEdit ? (
            <Button
              size="small"
              type="text"
              icon={<SlPencil className="secondary" style={{ verticalAlign: 'middle' }} />}
              onClick={() => setEdit(true)}
            />
          ) : undefined}
        </>
      )}
    </div>
  );
};

export default ProjectTag;
