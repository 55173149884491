import { FC } from 'react';
import { useSelector } from 'react-redux';
import { FiUser } from 'react-icons/fi';
import { Form, FormInstance } from 'antd';
import SelectUserAvatar from 'components/SelectUserAvatar';
import { RootState } from 'store';
import { UserResponse } from 'types';

interface SelectResponsibleProps {
  state: any;
  form: FormInstance;
  setState: any;
  finalProjectId: any;
}

const SelectResponsible: FC<SelectResponsibleProps> = ({ state, form, setState, finalProjectId }) => {
  const user = useSelector((store: RootState) => store.auth.user) as UserResponse;

  const stateResponsible = state?.responsibles?.[0];

  const defaultResponsibleOptions = [
    { value: user.uuid, label: user.name, user },
    ...(stateResponsible && stateResponsible.uuid !== user.uuid
      ? [
          {
            value: stateResponsible.uuid,
            label: stateResponsible.name,
            user: stateResponsible,
          },
        ]
      : []),
  ];

  return (
    <Form.Item
      label={
        <>
          <FiUser />
          Assignee
        </>
      }
      name="responsibles"
      style={{ marginBottom: 0 }}
      rules={[
        ...(state?.recurrence
          ? [
              {
                required: true,
                message: 'This field is required',
              },
            ]
          : []),
      ]}
    >
      <SelectUserAvatar
        placeholder="Select a user"
        size="middle"
        projectId={finalProjectId}
        defaultOptions={defaultResponsibleOptions}
        onChange={(_, option) => {
          setState((prevState: any) => ({ ...prevState, responsibles: option ? [option.user] : [] }));

          const statusContact = ['waiting_approval', 'pending', 'completed', 'cancelled', 'needs_attention'];

          if (option?.user?.type === 'contact' && !statusContact.includes(form.getFieldValue('status'))) {
            setState((prevState: any) => ({ ...prevState, status: 'pending' }));
            form.setFieldsValue({ status: 'pending' });
          }

          const newFollowers = option ? state?.followers?.filter((x: any) => x.uuid !== option.value) : state?.followers;

          if (newFollowers && newFollowers.length !== state.followers.length) {
            const ids = newFollowers.map((x: any) => x.uuid);

            setState((prevState: any) => ({ ...prevState, followers: newFollowers }));

            form.setFieldsValue({ followers: ids });
          }
        }}
      />
    </Form.Item>
  );
};

export default SelectResponsible;
