import { FC, ReactNode, useRef } from 'react';
import { Empty, Form, Select, SelectProps, Spin } from 'antd';
import type { FormItemProps } from 'antd/lib/form/FormItem';
import type { SizeType } from 'antd/lib/config-provider/SizeContext';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';

interface SelectSearchFrontendProps {
  name?: string;
  label?: string;
  placeholder?: string;
  searchKeys?: string[];
  rules?: FormItemProps['rules'];
  className?: string;
  size?: SizeType;
  optionRender?: (item: any) => ReactNode;
  optionValue?: string;
  optionLabel?: string | ((item: any) => string);
  allowClear?: boolean;
  autoFocus?: boolean;
  fetchOnMount?: boolean;
  bordered?: boolean;
  noPadding?: boolean;
  onChange?: SelectProps<any>['onChange'];
  storeKeySelector: string;
  storeAction: any;
  initialOption?: any;
  filter?: (item: any) => boolean;
}

const SelectSearchFrontend: FC<SelectSearchFrontendProps> = ({
  name,
  label,
  placeholder,
  searchKeys = ['title'],
  rules,
  className,
  size,
  optionRender,
  optionValue = 'uuid',
  optionLabel = 'title',
  allowClear,
  autoFocus,
  fetchOnMount = true,
  bordered,
  noPadding = false,
  onChange,
  storeKeySelector,
  storeAction,
  initialOption,
  filter,
}) => {
  const fetchOnMountRef = useRef(true);
  const { loading, data } = useSelector((globalState: any) => globalState[storeKeySelector]);
  const dispatch = useDispatch();
  const items = data?.data;

  if ((fetchOnMount && fetchOnMountRef.current) || data === null) {
    fetchOnMountRef.current = false;
    if (!loading) {
      dispatch(storeAction());
    }
  }

  const filterOption = (search: string, option?: any) => {
    return searchKeys.length > 1
      ? !!searchKeys.find((key) => (get(option?.resource, key) ?? '').toLowerCase().includes(search.toLowerCase()))
      : (option?.resource?.[searchKeys[0]] ?? '').toLowerCase().includes(search.toLowerCase());
  };

  let options = items ?? (initialOption ? [initialOption] : undefined);

  options = filter ? options?.filter(filter) : options;

  return (
    <Form.Item className={className} label={label} name={name} rules={rules} noStyle={!label}>
      <Select
        dropdownClassName={noPadding ? 'no-padding' : ''}
        filterOption={filterOption}
        loading={loading}
        placeholder={placeholder}
        className={!label ? className : undefined}
        size={size}
        allowClear={allowClear}
        autoFocus={autoFocus}
        showSearch
        bordered={bordered}
        onChange={onChange}
        optionLabelProp="display"
        notFoundContent={
          loading && data === null ? (
            <div style={{ textAlign: 'center', padding: '12px 18px' }}>
              <Spin />
            </div>
          ) : (
            <Empty />
          )
        }
        options={options?.map((item: any) => {
          const display = typeof optionLabel === 'function' ? optionLabel(item) : item[optionLabel];

          return {
            key: item.uuid,
            value: item[optionValue],
            label: optionRender ? optionRender(item) : display,
            display,
            resource: item,
          };
        })}
      />
    </Form.Item>
  );
};

export default SelectSearchFrontend;
